<template>
  <b-card>
    <b-alert v-model="showAlert" variant="danger" dismissible>{{
      alertMessage
    }}</b-alert>

    <b-form @submit.prevent>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <b-form-group
            id="ProjectTitle"
            label="Project Title"
            label-for="ProjectTitle"
            :label-class="['form-label', 'required']"
            :class="{ 'form-group--error': $v.formData.projectTitle.$error }"
          >
            <b-form-input
              id="ProjectTitle"
              v-model="formData.projectTitle"
              placeholder="Project Title"
              trim
              :class="{ 'is-invalid': $v.formData.projectTitle.$error }"
            ></b-form-input>
            <b-form-invalid-feedback>
              Project title is required
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6">
          <b-form-group
            id="language-selection"
            label="Language"
            label-for="language-dropdown"
            label-class="form-label required"
            :class="{ 'form-group--error': $v.formData.languageId.$error }"
          >
            <b-dropdown
              id="language-dropdown"
              :text="selectedLanguageText"
              ref="language-dropdown"
              block
              menu-class="w-100 scrollable"
              no-caret
              lazy
              variant="brand-dropdown"
              :class="{ 'is-invalid': $v.formData.languageId.$error }"
            >
              <b-dropdown-item
                v-for="(language, index) in languages"
                :key="language.languageCode + '_' + index"
                @click="formData.languageId = language.languageId"
                :active="language.languageId == formData.languageId"
                >{{ language.languageName }}</b-dropdown-item
              >
            </b-dropdown>
            <b-form-invalid-feedback>
              Language is required
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div
          role="group"
          class="form-group col-xs-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4"
        >
          <label for="releaseDate" class="form-label required">{{
            "Released Date"
          }}</label>
          <div
            class="flex w-full"
            :class="{ 'is-invalid': $v.formData.deploymentDate.$error }"
          >
            <v-date-picker
              ref="releaseDate"
              class="flex-grow"
              :masks="masks"
              v-model="formData.deploymentDate"
              is-required
              :popover="{ visibility: 'click' }"
              :model-config="modelConfig"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue }">
                <div class="d-flex custom-datepicker">
                  <input
                    class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                    :class="{
                      'is-invalid': $v.formData.deploymentDate.$error,
                    }"
                    :value="inputValue"
                    @click="showStartDatePicker"
                    :placeholder="dateFormat"
                    readonly
                  />
                  <div
                    class="picker-icon"
                    slot="afterDateInput"
                    role="button"
                    @click="showStartDatePicker"
                  >
                    <i class="icon-date"></i>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <b-form-invalid-feedback>
            Relesed date is required
          </b-form-invalid-feedback>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4">
          <b-form-group
            id="ReleasedVersion"
            label="Released Version"
            label-for="ReleasedVersion"
            :label-class="['form-label', 'required']"
            :class="{
              'form-group--error': $v.formData.version.$error,
            }"
          >
            <b-form-input
              id="ReleasedVersion"
              v-model="formData.version"
              placeholder="Released Version"
              trim
              :class="{ 'is-invalid': $v.formData.version.$error }"
            ></b-form-input>
            <b-form-invalid-feedback>
              Released version is required
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4">
          <b-form-group
            id="icon"
            label="Icon"
            label-for="icon"
            :label-class="['form-label', 'required']"
            :class="{ 'form-group--error': $v.formData.icon.$error }"
          >
            <b-form-input
              id="icon"
              v-model="formData.icon"
              placeholder="icon"
              trim
              :class="{ 'is-invalid': $v.formData.icon.$error }"
            ></b-form-input>

            <b-form-invalid-feedback>
              Icon is required
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <b-form-group
        id="shortDesc"
        label="Short Description"
        label-for="shortDesc"
        :label-class="['form-label', 'required']"
        :class="{ 'form-group--error': $v.formData.description.$error }"
      >
        <b-form-textarea
          id="shortDesc"
          v-model="formData.description"
          placeholder="Short Description"
          rows="2"
          max-rows="6"
          :class="{ 'is-invalid': $v.formData.description.$error }"
        ></b-form-textarea>

        <b-form-invalid-feedback>
          Short description is required
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <!-- <div class="user-manual-content" v-html="formData.features"></div> -->
        <div class="user-manual-edit">
          <label class="form-label required">{{ "Features" }}</label>
          <editor
            :api-key="tinymceApiKey"
            v-model="formData.features"
            :init="{
              height: 200,
              menubar: false,
              branding: false,
              plugins:
                'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
              menubar: 'file edit view insert format tools table help',
              toolbar:
                'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
            }"
            :class="{ 'is-invalid': $v.formData.features.$error }"
          />
          <b-form-invalid-feedback>
            Features is required
          </b-form-invalid-feedback>
        </div>
      </b-form-group>
    </b-form>
    <div class="d-flex flex-row-reverse mt-3">
      <b-button @click="handleValidate" variant="brand-primary"
        >Submit
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { isObject } from "lodash";
import config from "../../../../../config";
import { mapActions, mapMutations, mapGetters } from "vuex";
const { dateFormat } = config;
export default {
  name: "VersionForm",
  props: ["mode"],
  data() {
    return {
      tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
      formData: {
        historyId: 0,
        languageId: "",
        projectTitle: "",
        deploymentDate: "",
        version: "",
        description: "",
        features: "",
        icon: "",
      },
      showAlert: false,
      alertMessage: "",
      dateFormat: dateFormat,
      masks: {
        input: dateFormat,
      },
      modelConfig: {
        type: "string",
        mask: "iso",
      },
      isEditMode: false,
      htmlCode: "",
    };
  },
  computed: {
    ...mapGetters(["languages"]),
    selectedLanguageText() {
      let _selected = this.languages.find(
        (item) => item.languageId == this.formData.languageId
      );

      if (_selected && Object.keys(_selected).length)
        return _selected.languageName;
      else return "Select";
    },
  },
  methods: {
    ...mapActions("adminSetting", ["getVHistoryById", "manageversionhistory"]),
    ...mapActions(["getLanguages"]),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    async handleValidate() {
      this.$v.formData.$touch();

      if (this.$v.formData.$invalid) return;

      this.generalSetItem({ resource: "isLoading", payload: true });

      this.manageversionhistory({ ...this.formData, userId: this.loggedUserId })
        .then((response) => {
          this.generalSetItem({ resource: "isLoading", payload: false });
          if (response.data == 1) {
            this.$router.push({ name: "version-history-list" });
          } else {
            this.showAlert = true;
            this.alertMessage = response.error;
          }
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    showStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.releaseDate.togglePopover();
      });
    },
  },
  validations: {
    formData: {
      languageId: { required },
      projectTitle: { required, maxLength: maxLength(50) },
      deploymentDate: { required },
      version: { required },
      description: { required },
      features: { required },
      icon: { required },
    },
  },
  mounted() {
    this.generalSetItem({ resource: "isLoading", payload: true });
    this.getLanguages()
      .then(() => {
        this.getTranslationModules({ userId: this.loggedUserId })
          .then(() => {
            this.generalSetItem({ resource: "isLoading", payload: false });
          })
          .catch(() => {
            this.generalSetItem({ resource: "isLoading", payload: false });
          });
      })
      .catch(() => {
        this.generalSetItem({ resource: "isLoading", payload: false });
      });
    if (this.mode == "edit") {
      let id = this.$route.params.id;
      this.generalSetItem({ resource: "isLoading", payload: true });
      this.getVHistoryById({ id, userId: this.loggedUserId })
        .then((response) => {
          this.generalSetItem({ resource: "isLoading", payload: false });

          if (isObject(response) && Object.keys(response).length) {
            let data = response;
            const {
              id,
              languageId,
              projectTitle,
              deploymentDate,
              version,
              description,
              features,
              icon,
            } = data;

            this.formData = Object.assign(
              {},
              {
                historyId: id,
                languageId: languageId ? languageId : 1,
                projectTitle,
                deploymentDate: new Date(deploymentDate),
                version,
                status: 1,
                description,
                features,
                icon,
              }
            );
          }
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
          //return back to listing page because error in fetch record
          this.$router.push({ name: "version-history-list" });
        });

      this.generalSetItem({ resource: "isLoading", payload: false });
    }
  },
  destroyed() {
    this.formData = {
      historyId: 0,
      languageId: "",
      projectTitle: "",
      deploymentDate: "",
      version: "",
      description: "",
      features: "",
      icon: "",
    };
  },
};
</script>

<style scoped></style>
